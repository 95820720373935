/* eslint-disable */
// import auth from "@/middleware/auth";
export default [{
    path: "/seleccionar-proyecto",
    name: "seleccionar-proyecto",
    component: () => import("@/views/home.vue"),
    meta: {
      // middleware: auth,
      layout: "proyecto",
    },
  },
  //seleccionar tipo logueo
  {
    path: "/seleccionar-log-type",
    name: "seleccionar-log-type",
    component: () => import("@/views/postLog.vue"),
    meta: {
      // middleware: auth,
      layout: "proyecto",
    },
  },
  //RESTRICCIONES  X LIBERAR 
  {
    path: '/restricciones-por-liberar',
    name: 'restricciones-liberar',
    component: () => import('@/views/restriccionesxliberar/index.vue'),
    meta: {
      pageTitle: 'Lista de restricciones Por Liberar',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/restricciones-por-liberar-2',
    name: 'restricciones-liberar-2',
    component: () => import('@/views/restricciones/index2.vue'),
    meta: {
      pageTitle: 'Lista de restricciones Asignadas',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  //RESTRICCIONES  
  {
    path: '/restricciones',
    name: 'restricciones',
    component: () => import('@/views/restricciones/index.vue'),
    meta: {
      pageTitle: 'Lista de restricciones',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
 
  {
    path: '/restricciones/registro/:id',
    name: 'restricciones-registro',
    component: () => import('@/views/restricciones/add.vue'),
    meta: {
      pageTitle: 'Crear Restricción',
      breadcrumb: [{
        text: 'Crear',
        active: true,
      }, ],
    },
  },
  {
    path: '/restricciones/editar/:id',
    name: 'restricciones-editar',
    component: () => import('@/views/restricciones/edit.vue'),
    meta: {
      pageTitle: 'Editar Restricción',
      breadcrumb: [{
        text: 'Editar',
        active: true,
      }, ],
    },
  },
  {
    path: '/restricciones/vista/:id',
    name: 'restricciones-vista',
    component: () => import('@/views/restricciones/view.vue'),
    meta: {
      pageTitle: 'Vista Restricción',
      breadcrumb: [{
        text: 'Vista',
        active: true,
      }, ],
    },
  },
  {
    path: '/restricciones/importar-datos',
    name: 'restricciones-importar-datos',
    component: () => import('@/views/restricciones/importar/index.vue'),
    meta: {
      pageTitle: 'Importar Datos',
      breadcrumb: [{
        text: 'Importar Datos',
        active: false,
      }, ],
    },
  },
  //PROVICIONAL
  {
    path: '/provisionales',
    name: 'provisionales',
    component: () => import('@/views/provisionales/index.vue'),
    meta: {
      pageTitle: 'Lista de provisionales',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/provisionales/importar-datos',
    name: 'provisionales-importar-datos',
    component: () => import('@/views/provisionales/importar/index.vue'),
    meta: {
      pageTitle: 'Importar Datos',
      breadcrumb: [{
        text: 'Importar Datos',
        active: false,
      }, ],
    },
  },
  // {
  //   path: '/provisionales/registro/:id',
  //   name: 'provisionales-registro',
  //   component: () => import('@/views/provisionales/add.vue'),
  //   meta: {
  //     pageTitle: 'Crear Restricción Provisional',
  //     breadcrumb: [{
  //       text: 'Crear',
  //       active: true,
  //     }, ],
  //   },
  // },
  // {
  //   path: '/provisionales/editar/:id',
  //   name: 'provisionales-editar',
  //   component: () => import('@/views/provisionales/edit.vue'),
  //   meta: {
  //     pageTitle: 'Editar Restricción Provisional',
  //     breadcrumb: [{
  //       text: 'Editar',
  //       active: true,
  //     }, ],
  //   },
  // },
  {
    path: '/provisionales/completar/:id',
    name: 'provisionales-completar',
    component: () => import('@/views/provisionales/completar.vue'),
    meta: {
      pageTitle: 'Completar Datos Restricción Provisional',
      breadcrumb: [{
        text: 'Completar',
        active: true,
      }, ],
    },
  },
  //RFI
  {
    path: '/rfi',
    name: 'rfi',
    component: () => import('@/views/rfi/index.vue'),
    meta: {
      pageTitle: 'Lista de RFI',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/rfi/registro/:id',
    name: 'rfi-registro',
    component: () => import('@/views/rfi/add.vue'),
    meta: {
      pageTitle: 'Crear RFI',
      breadcrumb: [{
        text: 'Crear',
        active: true,
      }, ],
    },
  },
  {
    path: '/rfi/editar/:id',
    name: 'rfi-editar',
    component: () => import('@/views/rfi/edit.vue'),
    meta: {
      pageTitle: 'Editar RFI',
      breadcrumb: [{
        text: 'Editar',
        active: true,
      }, ],
    },
  },
  {
    path: '/rfi/importar-datos',
    name: 'rfi-importar-datos',
    component: () => import('@/views/rfi/importar/index.vue'),
    meta: {
      pageTitle: 'Importar RFI',
      breadcrumb: [{
        text: 'Importar Datos',
        active: false,
      }, ],
    },
  },
   //ALERTAS
   {
    path: '/alertas',
    name: 'alertas',
    component: () => import('@/views/alertas/index.vue'),
    meta: {
      pageTitle: 'Lista de Alertas',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/alertas-cowner',
    name: 'alertas-cowner',
    component: () => import('@/views/alertas/indexCowner.vue'),
    meta: {
      pageTitle: 'Lista de Alertas de Co Dueños',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
   //CONSULTAS
   {
    path: '/consultas',
    name: 'consultas',
    component: () => import('@/views/consultas/index.vue'),
    meta: {
      pageTitle: 'Lista de Consultas',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
   //CONSULTAS CO OWNER
   {
    path: '/consultas-cowner',
    name: 'consultas-cowner',
    component: () => import('@/views/consultasCowner/index.vue'),
    meta: {
      pageTitle: 'Lista de Consultas',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  //LECCIONES
  {
    path: '/lecciones-inbox',
    name: 'lecciones-inbox',
    component: () => import('@/views/lecciones/inbox.vue'),
    meta: {
      pageTitle: 'Bandeja Lecciones Aprendidas',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/lecciones',
    name: 'lecciones',
    component: () => import('@/views/lecciones/index.vue'),
    meta: {
      pageTitle: 'Lista de Lecciones Aprendidas',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/lecciones/registro',
    name: 'lecciones-registro',
    component: () => import('@/views/lecciones/add.vue'),
    meta: {
      pageTitle: 'Agregar Lección Aprendida',
      breadcrumb: [{
        text: 'Crear',
        active: true,
      }, ],
    },
  },
  {
    path: '/lecciones/editar/:id',
    name: 'lecciones-editar',
    component: () => import('@/views/lecciones/edit.vue'),
    meta: {
      pageTitle: 'Editar Lección Aprendida',
      breadcrumb: [{
        text: 'Editar',
        active: true,
      }, ],
    },
  },
  {
    path: '/lecciones/vista/:id',
    name: 'lecciones-vista',
    component: () => import('@/views/lecciones/view.vue'),
    meta: {
      pageTitle: 'Visualizar Lección Aprendida',
      breadcrumb: [{
        text: 'visualizar',
        active: true,
      }, ],
    },
  },
  //ACTIVIDADES
  {
    path: '/mantenedores/actividad/importar-datos',
    name: 'mantenedores-actividad-importar-datos',
    component: () => import('@/views/mantenedores/localizacion/importar/index.vue'),
    meta: {
      pageTitle: 'Importar Datos',
      breadcrumb: [{
        text: 'Importar Datos',
        active: false,
      }, ],
    },
  },
  //REPORTES
  {
    path: '/reportes',
    name: 'reportes',
    component: () => import('@/views/reportes/index.vue'),
    meta: {
      pageTitle: 'Lista de reportes',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  //Scheduler
  {
    path: '/scheduler',
    name: 'scheduler',
    component: () => import('@/views/scheduler/index.vue'),
    meta: {
      pageTitle: 'Lista de Tareas Programadas',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/scheduler-cowner',
    name: 'scheduler-cowner',
    component: () => import('@/views/scheduler/indexCowner.vue'),
    meta: {
      pageTitle: 'Lista de Tareas Programadas de Co Dueños',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  //PLANTILLAS 
  {
    path: '/plantillas/areas',
    name: 'template-areas',
    component: () => import('@/views/plantillas/areas/index.vue'),
    meta: {
      pageTitle: 'Lista de Areas',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/plantillas/categoria',
    name: 'template-categoria',
    component: () => import('@/views/plantillas/categoria/index.vue'),
    meta: {
      pageTitle: 'Lista de Categorias',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/plantillas/sub-categoria',
    name: 'template-sub-categoria',
    component: () => import('@/views/plantillas/sub-categoria/index.vue'),
    meta: {
      pageTitle: 'Lista de Sub Categorias',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },  
  {
    path: '/plantillas/especialidades',
    name: 'template-especialidades',
    component: () => import('@/views/plantillas/especialidades/index.vue'),
    meta: {
      pageTitle: 'Lista de Especialidades',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  //MANTENEDORES  
  {
    path: '/mantenedores/areas',
    name: 'areas',
    component: () => import('@/views/mantenedores/areas/index.vue'),
    meta: {
      pageTitle: 'Lista de Areas',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/categoria',
    name: 'categoria',
    component: () => import('@/views/mantenedores/categoria/index.vue'),
    meta: {
      pageTitle: 'Lista de Categorias',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/sub-categoria',
    name: 'sub-categoria',
    component: () => import('@/views/mantenedores/sub-categoria/index.vue'),
    meta: {
      pageTitle: 'Lista de Sub Categorias',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/dueno',
    name: 'dueño',
    component: () => import('@/views/mantenedores/dueño/index.vue'),
    meta: {
      pageTitle: 'Lista de Dueños',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/co-dueno',
    name: 'co-dueño',
    component: () => import('@/views/mantenedores/co-dueño/index.vue'),
    meta: {
      pageTitle: 'Lista de Co Dueños',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/localizacion',
    name: 'localizacion',
    component: () => import('@/views/mantenedores/localizacion/hijo/index.vue'),
    meta: {
      pageTitle: 'Lista de Localizaciones',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/actividad',
    name: 'actividad',
    component: () => import('@/views/mantenedores/localizacion/index.vue'),
    meta: {
      pageTitle: 'Lista de Actividades',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/proyectos',
    name: 'proyectos',
    component: () => import('@/views/mantenedores/proyectos/index.vue'),
    meta: {
      pageTitle: 'Lista de Proyectos',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/especialidades',
    name: 'especialidades',
    component: () => import('@/views/mantenedores/especialidades/index.vue'),
    meta: {
      pageTitle: 'Lista de Especialidades',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/agrupaciones',
    name: 'agrupaciones',
    component: () => import('@/views/mantenedores/agrupaciones/index.vue'),
    meta: {
      pageTitle: 'Lista de Agrupaciones',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/usuarios',
    name: 'usuarios',
    component: () => import('@/views/mantenedores/usuarios/index.vue'),
    meta: {
      pageTitle: 'Lista de Usuarios',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/usuarios-corporativo',
    name: 'usuarios-corporativo',
    component: () => import('@/views/mantenedores/usuarios-corporativo/index.vue'),
    meta: {
      pageTitle: 'Lista de Usuarios',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/periodos',
    name: 'periodos',
    component: () => import('@/views/mantenedores/periodos/index.vue'),
    meta: {
      pageTitle: 'Lista de Periodos',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/division',
    name: 'division',
    component: () => import('@/views/mantenedores/division/index.vue'),
    meta: {
      pageTitle: 'Lista de Divisiones',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/tipo-obra',
    name: 'tipo-obra',
    component: () => import('@/views/mantenedores/tipo-obra/index.vue'),
    meta: {
      pageTitle: 'Lista de Tipo de Obras',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/especialidades-lecciones',
    name: 'especialidades-lecciones',
    component: () => import('@/views/mantenedores/especialidades-lecciones/index.vue'),
    meta: {
      pageTitle: 'Lista de Especialidades de Lecciones',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
  {
    path: '/mantenedores/tipificacion',
    name: 'tipificacion',
    component: () => import('@/views/mantenedores/tipificacion/index.vue'),
    meta: {
      pageTitle: 'Lista de Tipificaciones',
      breadcrumb: [{
        text: 'Listar',
        active: true,
      }, ],
    },
  },
     //CONSULTAS
  {
    path: '/power-bi',
    name: 'power-bi',
    component: () => import('@/views/powerbi/index.vue'),
    
  }, 
  {
    path: '/graph-projects',
    name: 'graph-projects',
    component: () => import('@/views/reportes/mainGraph.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [{
        text: 'Graficas',
        active: true,
      }, ],
    },
  }, 
]