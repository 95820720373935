/* eslint-disable */
import useJwt from '@/auth/jwt/useJwt'
import navMenuItems1 from "@/navigation/vertical";
import navMenuItems2 from "@/navigation/horizontal";
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData')
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const cleanStorageBySocket=()=>{
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)   
    localStorage.removeItem('userData')
    localStorage.removeItem('project_id')
    // Reset ability
    return true
}
/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if(!userRole) return { name: 'auth-login' }
  const log_type = localStorage.getItem('log_type')
  const {is_lesson =false, is_restriction = false,role_lesson,role_restriction} = userRole.role_user[0];
  if(log_type == 'Restricciones'){
    if(role_restriction === 'user'){
      return 'provisionales'
    }
    return 'restricciones'
  }
  else if(log_type == "Lecciones"){
    return 'lecciones'
  } else if(log_type == "Corporativo"){
    if(role_restriction == 'user-collection'){
      return 'graph-projects'
    }else{
      return 'proyectos'
    }
  } else return 'rfi'
  /* if(is_restriction){
    if(role_restriction === 'user'){
      return 'provisionales'
    }
    return 'restricciones'
  }
  if(is_lesson){
    return 'lecciones'
  } */

  // if (userRole === 'admin') return 'restricciones'
  // if (userRole === 'planner') return 'restricciones'
  // if (userRole === 'user') return 'provisionales'
  // if (userRole === 'admin-lesson' || userRole === 'user-lesson'  || userRole === 'reviewer-lesson') return 'lecciones'
  // return { name: 'auth-login' }
}
//se cambio multirole
export const getUserAccesPermissionsNew =(role)=>{
  const {is_restriction,is_lesson,role_restriction,role_lesson} = role
  let perRestriction  = [];
  let perLesson = [];
  if(is_restriction){
    perRestriction = getUserAcessPermissions(role_restriction);
  }
  if(is_lesson){
    perLesson = getUserAcessPermissions(role_lesson);
  }
  const permissions = perRestriction.concat(perLesson);
  
  return permissions
}
export const getUserAcessPermissions = (role) => {

  let permissions = [];
  switch (role) {  
    case 'user-office':
      permissions = [     
        "seleccionar-log-type",   
        "alertas-cowner",
        "graph-projects"
      ];
      break; 
    case "admin":
      permissions = [
        "seleccionar-proyecto",
        "seleccionar-log-type",
        "alertas",
        "mantenedor-agrupaciones",
        "template-areas",
        "template-especialidades",
        "template-categoria",
        "template-sub-categoria",
        "consultas",
        "consultas-cowner",
        "mantenedor-area",
        "mantenedor-categoria",
        "mantenedor-sub-categoria",
        "mantenedor-dueño",
        "mantenedor-co-dueño",
        "mantenedor-especialidades",
        "mantenedor-localizacion",
        "mantenedor-actividad",
        "mantenedores-actividad-importar-datos",
        "mantenedor-periodos",
        "mantenedor-proyectos",
        "mantenedor-usuarios",
        "mantenedor-usuarios-corporativo",
        "restricciones",
        "restricciones-registro",
        "restricciones-editar",
        "restricciones-vista",
        "restricciones-importar-datos",
        "rfi",
        "rfi-registro",
        "rfi-editar",
        "rfi-importar-datos",
        "provisionales",
        "provisionales-registro",
        "provisionales-editar",
        "provisionales-completar",
        "provisionales-importar-datos",       
        "reportes",
        "scheduler",
        "scheduler-cowner",
        "alertas-cowner",
        "power-bi",
        "graph-projects"
      ];
      break;
    case "planner":
      permissions = [
        "seleccionar-proyecto",
        "seleccionar-log-type",
        "alertas",
        "consultas",
        "mantenedor-area",
        "mantenedor-categoria",
        "mantenedor-sub-categoria",
        "mantenedor-dueño",
        "mantenedor-co-dueño",
        "mantenedor-especialidades",
        "mantenedor-localizacion",
        "mantenedor-actividad",
        "mantenedores-actividad-importar-datos",
        "mantenedor-periodos",
        "mantenedor-usuarios",
        "restricciones",
        "restricciones-liberar-2",
        "restricciones-liberar",
        "restricciones-registro",
        "restricciones-editar",
        "restricciones-vista",
        "restricciones-importar-datos",
        "rfi",
        "rfi-registro",
        "rfi-editar",
        "rfi-importar-datos",
        "provisionales",
        "provisionales-registro",
        "provisionales-editar",
        "provisionales-completar",
        "provisionales-importar-datos",        
        "reportes",
        "scheduler",
        "power-bi"
      ];
      break;
    case "user":
        permissions = [
          "seleccionar-proyecto",
          "seleccionar-log-type",
          "alertas",
          "restricciones",
          "restricciones-liberar-2",
          "restricciones-registro",
          "restricciones-editar",
          "restricciones-vista",
          "restricciones-importar-datos",
          "provisionales",
          "provisionales-registro",
          "provisionales-editar",
          "provisionales-completar",
          "provisionales-importar-datos",
          "reportes",
          "power-bi"
        ];
      break;
    case "user-collection":
        permissions = [
          "seleccionar-proyecto",
          "seleccionar-log-type",
          "alertas",
          "reportes",
          "power-bi",
          "graph-projects",
          "alertas-cowner",
        ];
      break;
      case "admin-lesson":
          permissions = [  
            "seleccionar-proyecto",   
            "seleccionar-log-type",
            "mantenedor-usuarios",
            "lecciones",         
            "lecciones-inbox",   
            "lecciones-registro",
            "lecciones-editar",
            "lecciones-vista",
            "mantenedor-division",
            "mantenedor-tipo-obra",
            "mantenedor-especialidades-lecciones",
            "mantenedor-tipificacion",
          ];
      break;
      default:
        permissions = [
          "lecciones",        
          "seleccionar-proyecto",
          "seleccionar-log-type",
          "lecciones-inbox",
          "lecciones-registro",
          "lecciones-editar",
          "lecciones-vista",
        ];
        break;
       
  }

  return permissions;
};


export const validateAccess = (route, permissions, role) => {
  let hasAccess = false;

  navMenuItems1.forEach((element) => {
    if (element.route && element.route.id == route.id) {
      if (permissions.indexOf(element.id) != -1) {
        hasAccess = true;
      }
    } else {
      if (element.children) {
        element.children.forEach((child) => {
          if (child.route && child.route.id == route.id) {
            if (permissions.indexOf(child.id) != -1) {
              hasAccess = true;
            }
          }
        });
      }
    }
  });

  return hasAccess;
};
export const validateAccess2 = (route, permissions, role) => {
  let hasAccess = false;  
  
  navMenuItems2.forEach((element) => {   
   
    if (element.route == route.name) {
      if (permissions.indexOf(element.id) != -1) {
      
        hasAccess = true;
      }
    } else {
      if (element.children) {
        element.children.forEach((child) => {
          if (child.route && (child.route.name ? child.route.name : child.route) == route.name) {
            if (permissions.indexOf(child.id) != -1) {
             
              hasAccess = true;
            }
          }
        });
      }
    }
  });
 
  return hasAccess;
};